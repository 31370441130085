import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-spinner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styles: `
        :host {
            display: block;
            position: relative;
            height: 100%;
            width: 100%;
            min-height: inherit;
            flex: 1;
        }

        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #333;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border-top-color: #eee;
            border-right-color: #eee;
            animation: spin 0.5s linear infinite;

            &.small {
                width: 30px;
                height: 30px;
            }
        }

        @keyframes spin {
            from {
                transform: rotate(0);
            }
            to {
                transform: rotate(360deg);
            }
        }
    `,
    template: `
        <div class="spinner" [class.small]="small"></div>`
})
export class SpinnerComponent {
    //TODO: deprecate
  @Input() small: boolean = false;
}
